
.accordion {
  border: none;
  text-decoration: none;
  /* border-radius: 2px; */
  

}

.accordion__item + .accordion__item {
  
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
}

.accordion__button {
  /* background-color: #f4f4f4; */
  /* color: #444; */
  cursor: pointer;
  /* padding: 18px; */
  width: 100%;
  text-align: left;
  border: none;
  text-decoration: none;
  /* margin: 25px; */
}

.accordion__button:focus {
  border: blue;
  text-decoration: none;
}

.accordion__button:hover {
  /* background-color: #ddd; */
}

/* .accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
} */

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

/* removes border radus */
[data-whatinput='mouse'] :focus,
[data-whatinput='touch'] :focus {
    outline: none;
}

.accordion__panel {
  padding-bottom: 10px;
  animation: fadein 0.5s ease-in-out;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
